import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
// import NotFound from 'containers/NotFound/NotFound';
// import PrivateRoute from 'config/PrivateRoute';
// import NonPrivateRoutes from 'config/NonPrivateRoutes';
import LazyLoad from './LazyLoad';

const Home = React.lazy(() => import('./containers/Home/HomePage'));
const AddVehicle = React.lazy(() => import('./containers/Seller/AddVehicle'));
const VehcileList = React.lazy(() => import('./containers/Vehicle/VehicleList'));
const VehicleDetails = React.lazy(() => import('./containers/Vehicle/VehicleDetails'));
const Faq = React.lazy(() => import('./containers/Home/Faq'));
const TermsConditions = React.lazy(() => import('./containers/Home/TermsConditions'));
const PrivacyPolicy = React.lazy(() => import('./containers/Home/PrivacyPolicy'));
const About = React.lazy(() => import('./containers/About/AboutPage'));
const Blog = React.lazy(() => import('./containers/Blog/BlogPage'));
const Contact = React.lazy(() => import('./containers/Contact/ContactPage'));

function Routes() {
  return (
    <React.Suspense fallback={<LazyLoad />}>
      <Switch>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/add-vehicle' element={<AddVehicle />} />
        <Route exact path='/vehicle-list' element={<VehcileList />} />
        <Route exact path='/vehicle-detail/:id' element={<VehicleDetails />} />
        <Route exact path='/faq' element={<Faq />} />
        <Route exact path='/terms-conditions' element={<TermsConditions />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/about-us' element={<About />} />
        <Route exact path='/contact-us' element={<Contact />} />
        {/* <Route exact path='*' element={<NotFound />} /> */}
      </Switch>
    </React.Suspense>
  );
}

export default Routes;
