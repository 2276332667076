import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/main.css';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Helmet>
      <title>Carbhar - One-Stop-Shop for Buying and Selling Cars</title>
      <meta
        name='description'
        content='Carbhar is the one car stop for all car sellers and buyers! We provide a platform that creates a
        great user experience to all those who are seeking to buy or sell a car, providing a wide radius of certified
        cars with ensured trust and minimal efforts but assure you better results.'
      />
      <meta name='keywords' content='Carbhar, buy cars, sell cars, certified cars, used cars, car dealers, car marketplace, car platform, car buying, car selling' />
    </Helmet>
    <App />
  </BrowserRouter>,
);

reportWebVitals();
